import { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { pdfSettings } from "../../pages/sales/pdf/pdfSettings";

import { orderActions } from "../../store/order/order-slice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useAddOrderMutation } from "../../api/ordersApi";
import OrderCheckoutCard from "../cards/order/OrderCheckoutCard";
import OrderPaymentConditionSelectionCard from "../cards/order/OrderPaymentConditionSelectionCard";
import OrderPaymentConditionSelectionModal from "../modals/order/OrderPaymentConditionSelectionModal";
import Order from "../../models/order/order.model";

import axios from "axios";
import { returnApiUrl } from "../../store/order/order-async-actions";

import Colors from "../../constants/Colors";
import formatDateToString from "../../util/formaters/formatDateToString";
import OrderSaveSuccessModal from "../modals/order/OrderSaveSuccessModal";
import { exitPageActions } from "../../store/exitPage/exit-page-slice";

import { StyleSheet, css } from "aphrodite";

import { logoIvitech, logoFloral } from "../../pages/sales/base64/base64";

const OrderCheckout = () => {
  const navigate = useNavigate();
  const order = useAppSelector((state) => state.order.order);
  const orderClient = useAppSelector((state) => state.order.selectedClient);
  const itemsDetail = useAppSelector((state) => state.order.itemsDetail);
  const selectedPaymentCondition = useAppSelector(
    (state) => state.order.selectedPaymentCondition
  );
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );
  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );
  const [addOrder] = useAddOrderMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isRascunho, setIsRascunho] = useState(false);

  const [imgLink, setImgLink] = useState("");
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeigth, setImgHeigth] = useState(0);
  const [base64, setBase64] = useState<any>("");
  const [showBtn, setShowBtn] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getImage();
  }, []);

  useEffect(() => {
    toDataURL(imgLink).then((dataUrl) => {
      console.log("RESULT:", dataUrl);
      setBase64(dataUrl);
    });
  }, [imgLink]);

  console.log(imgWidth);
  console.log(imgHeigth);
  console.log(base64);

  const headers = () => {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  };

  function getImage() {
    const API_URL = returnApiUrl();
    axios
      .get(`${API_URL}/configuracao`, headers())
      .then((response) => {
        setImgLink(response.data.urlLogo);
        console.log(response.data.urlLogo);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toDataURL = (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const sendOrderToDatabase = async (updatedOrder: Order): Promise<void> => {
    setIsWaiting(true);
    try {
      setOpenSuccessModal(true);
      await addOrder(updatedOrder).unwrap();
      dispatch(orderActions.resetOrder());
      dispatch(exitPageActions.changeCanExitSales(true));
      setIsWaiting(false);

      !isWaiting && navigate("/vendas");
      console.log(updatedOrder);
    } catch (err) {
      setIsWaiting(false);
      setIsError(true);
    }
  };

  const beforeTransmitting = (rascunho: boolean) => {
    let updatedOrder = { ...order };
    const transmissionDate = formatDateToString();
    transmissionDate.data = transmissionDate.data;

    if (rascunho) {
      updatedOrder.situacaoId = "0";
      setIsRascunho(true);
    } else {
      updatedOrder.situacaoId = "1";
      updatedOrder.transmissao = { ...transmissionDate };
      setIsRascunho(false);
    }
    dispatch(orderActions.transmitOrder({ rascunho, transmissionDate }));

    sendOrderToDatabase(updatedOrder);
    setShowBtn(!showBtn);
  };

  const pedidoID = order.id;
  // const logo = logoIvitech;

  console.log(order);
  console.log(itemsDetail);

  let itList: any = [];
  let sum = 0;

  if (order.itens) {
    order.itens.map((res) => {
      itList.push(res.quantidade);
    });
    sum = itList.reduce((partialSum: any, a: any) => partialSum + a, 0);
  }

  const getPDF = () => {
    let logo = imgLink === undefined ? imgLink : base64;

    pdfSettings(
      pedidoID,
      logo,
      order,
      orderClient,
      selectedPaymentCondition,
      itemsDetail,
      sum,
      imgWidth,
      imgHeigth
    );
  };

  return (
    <>
      <OrderSaveSuccessModal
        isRascunho={isRascunho}
        openModal={openSuccessModal}
      />
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <Grid
          container
          mt={1}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          width={{
            md: "40%",
            xs: "95%",
          }}
        >
          <OrderPaymentConditionSelectionModal
            openModal={openModal}
            toggle={() => setOpenModal(!openModal)}
          />
          <OrderCheckoutCard />
          <OrderPaymentConditionSelectionCard
            toggleModal={() => setOpenModal(true)}
          />
          {isError && (
            <Grid item xs={12} md={12} justifyContent={"center"}>
              <Alert
                onClose={() => setIsError(false)}
                sx={{ width: { md: "100%", xs: "100%" } }}
                severity="error"
              >
                <AlertTitle>Erro</AlertTitle>
                Houve um erro ao realizar o pedido/salvar rascunho.{"\n"}
                Verifique sua conexão com a internet ou entre em contato com o
                administrador do sistema
              </Alert>
            </Grid>
          )}
          {order.condicaoPagamentoId && !isReadOnly && (
            <>
              <Grid item xs={6} md={6} textAlign={"center"} mt={2}>
                <Button
                  variant="outlined"
                  color="success"
                  sx={{
                    "&:disabled": {
                      color: Colors.muiPrimary,
                    },
                  }}
                  disabled={isWaiting}
                  onClick={() => beforeTransmitting(false)}
                >
                  {isWaiting ? <CircularProgress size={20} /> : "Transmitir"}
                </Button>
              </Grid>
              <Grid item xs={6} md={6} textAlign={"center"} mt={2}>
                <Button
                  variant="outlined"
                  sx={{
                    "&:disabled": {
                      color: Colors.muiPrimary,
                    },
                  }}
                  disabled={isWaiting}
                  onClick={() => beforeTransmitting(true)}
                >
                  {isWaiting ? <CircularProgress size={20} /> : "Rascunho"}
                </Button>
              </Grid>
            </>
          )}
          {showBtn || isReadOnly ? (
            <Grid item md={12} textAlign={"center"} mt={2}>
              <Button onClick={getPDF} className={css(styles.btn)}>
                {isWaiting ? <CircularProgress size={20} /> : "GERAR PDF"}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    backgroundColor: "#01A95E",
    color: "#fff",
    fontWeight: "bold",
    transitionDuration: 0.2,

    width: "100%",
    paddingTop: 8,
    ":hover": {
      opacity: 0.8,
    },
    ":active": {
      opacity: 0.8,
    },
  },
});

export default OrderCheckout;
